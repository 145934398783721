import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface DocumentState {
    documentId: string
    authorUid: string
    authorAvatarUrl: string
    authorNickname: string
    timestamp: number
    content: string
    thumbnailContent: any[]
    thumbnailImageSizes: any[]
    hashtags: string[]
    numUps: number
    numDowns: number
}

const initialState: DocumentState = {
    documentId: "",
    authorUid: "",
    authorAvatarUrl: "",
    authorNickname: "",
    timestamp: 0,
    content: "",
    thumbnailContent: [],
    thumbnailImageSizes: [],
    hashtags: [],
    numUps: 0,
    numDowns: 0,
}

export const document = createSlice({
    name: "document",
    initialState,
    reducers: {
        setDocumentId(state, action: PayloadAction<string>) {
            state.documentId = action.payload
        },

        setAuthorUid(state, action: PayloadAction<string>) {
            state.authorUid = action.payload
        },

        setAuthorAvatarUrl(state, action: PayloadAction<string>) {
            state.authorAvatarUrl = action.payload
        },

        setAuthorNickname(state, action: PayloadAction<string>) {
            state.authorNickname = action.payload
        },

        setTimestamp(state, action: PayloadAction<number>) {
            state.timestamp = action.payload
        },

        setContent(state, action: PayloadAction<string>) {
            state.content = action.payload
        },

        setThumbnailContent(state, action: PayloadAction<any[]>) {
            state.thumbnailContent = action.payload
        },

        setThumbnailImageSizes(state, action: PayloadAction<any[]>) {
            state.thumbnailImageSizes = action.payload
        },

        setHashtags(state, action: PayloadAction<string[]>) {
            state.hashtags = action.payload
        },

        setNumUps(state, action: PayloadAction<number>) {
            state.numUps = action.payload
        },

        setNumDowns(state, action: PayloadAction<number>) {
            state.numDowns = action.payload
        },
    },
})

export const documentActions = document.actions
export default document.reducer
