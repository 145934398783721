import {configureStore} from "@reduxjs/toolkit"
import coreReducer from "./core"
import loginReducer from "./login"
import eulaReducer from "./eula"
import homeReducer from "./home"
import readReducer from "./read"
import documentReducer from "./document"
import writeReducer from "./write"
import profileReducer from "./profile"

export const store = configureStore({
    reducer: {
        core: coreReducer,
        login: loginReducer,
        eula: eulaReducer,
        home: homeReducer,
        read: readReducer,
        profile: profileReducer,
        document: documentReducer,
        write: writeReducer,
    }
})

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch

export default store
