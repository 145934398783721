import CircularProgress from '@mui/material/CircularProgress'
import Box from '@mui/material/Box'
import "./mobile_loading.css"

export function MobileLoading() {
    return (
        <div className="MobileLoading">
            <Box sx={{ display: "flex" }}>
                <CircularProgress />
            </Box>
        </div>
    );
}