import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface CoreState {
    isLoading: boolean,
}

const initialState: CoreState = {
    isLoading: false,
}

export const core = createSlice({
    name: "core",
    initialState,
    reducers: {
        setIsLoading(state, action: PayloadAction<boolean>) {
            state.isLoading = action.payload
        },
    },
})

export const coreActions = core.actions
export default core.reducer
