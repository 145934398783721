import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface LoginState {
    email: string
    password: string
}

const initialState: LoginState = {
    email: "",
    password: "",
}

export const login = createSlice({
    name: "login",
    initialState,
    reducers: {
        setEmail(state, action: PayloadAction<string>) {
            state.email = action.payload
        },

        setPassword(state, action: PayloadAction<string>) {
            state.password = action.payload
        },
    },
})

export const loginActions = login.actions
export default login.reducer
