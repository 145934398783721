import {useAppDispatch, useAppSelector} from "../../module/hook"
import {Loading} from "../loading/loading"
import {Header} from "../header"
import {Sidebar} from "../sidebar"
import {doc, getDoc, getFirestore} from "firebase/firestore"
import {getAuth} from "firebase/auth"
import {profileActions} from "../../module/profile"
import {coreActions} from "../../module/core"
import defaultAvatar from "../../asset/image/default_avatar.png"
import {useNavigate} from "react-router-dom"

export function Profile() {
    const auth = getAuth()

    const navigate = useNavigate()

    const isLoading = useAppSelector((state) => state.core.isLoading)

    if (auth.currentUser === null) {
        alert("프로필 기능은 로그인 후 사용하실 수 있습니다")

        navigate("/login")
    }

    return (
        <div className="Profile">
            {
                isLoading ?
                    <Loading/> :
                    <Body/>
            }
        </div>
    )
}

function Body() {
    return (
        <div className="Profile-Body">
            <Header/>
            <Sidebar/>
            <Main/>
        </div>
    )
}

function Main() {
    return (
        <div className="Profile-Main">
            <Avatar/>
        </div>
    )
}

function Avatar() {
    const auth = getAuth()
    const firestore = getFirestore()

    const avatarUrl = useAppSelector((state) => state.profile.avatarUrl)

    const dispatch = useAppDispatch()

    const docRef = doc(firestore, "user", auth.currentUser!.uid)

    dispatch(coreActions.setIsLoading(true))

    getDoc(docRef).then((docSnap) => {
        if (docSnap.exists()) {
            if (docSnap.data().hasOwnProperty("avatarUrl")) {
                dispatch(profileActions.setAvatarUrl(docSnap.data()["avatarUrl"]))
            } else {
                dispatch(profileActions.setAvatarUrl(""))
            }
        }
    })

    dispatch(coreActions.setIsLoading(false))

    if (avatarUrl !== "") {
        return (
            <div className="Profile-Avatar">
                <img src={avatarUrl}/>
            </div>
        );
    } else {
        return (
            <div className="Profile-Avatar">
                <img src={defaultAvatar}/>
            </div>
        );
    }
}