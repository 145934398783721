import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {DocumentState} from "./document"
import {CommentState} from "./comment";

export interface ReadState {
    document: DocumentState,
    comments: CommentState[],
    documents: DocumentState[],
}

const initialState: ReadState = {
    document: {
        documentId: "",
        authorUid: "",
        authorAvatarUrl: "",
        authorNickname: "",
        timestamp: 0,
        content: "",
        thumbnailContent: [],
        thumbnailImageSizes: [],
        hashtags: [],
        numUps: 0,
        numDowns: 0,
    },
    comments: [],
    documents: [],
}

export const read = createSlice({
    name: "read",
    initialState,
    reducers: {
        resetDocument(state) {
            state.document = {
                documentId: "",
                authorUid: "",
                authorAvatarUrl: "",
                authorNickname: "",
                timestamp: 0,
                content: "",
                thumbnailContent: [],
                thumbnailImageSizes: [],
                hashtags: [],
                numUps: 0,
                numDowns: 0,
            }
        },

        setDocument(state, action: PayloadAction<DocumentState>) {
            state.document = action.payload
        },

        appendComment(state, action: PayloadAction<CommentState>) {
            const isEqualTimestamp = (comment: CommentState) => comment.timestamp == action.payload.timestamp

            if (state.comments.findIndex(isEqualTimestamp) >= 0) {
                return
            } else {
                state.comments = [
                    ...state.comments,
                    action.payload,
                ].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
            }
        },

        resetComments(state) {
            state.comments = []
        },

        appendDocument(state, action: PayloadAction<DocumentState>) {
            const isEqualTimestamp = (document: DocumentState) => document.timestamp == action.payload.timestamp

            if (state.documents.findIndex(isEqualTimestamp) >= 0) {
                return
            } else {
                state.documents = [
                    ...state.documents,
                    action.payload,
                ].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
            }
        },
    },
})

export const readActions = read.actions
export default read.reducer
