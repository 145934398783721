import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface EulaState {
    isEulaCheck: boolean
    isAgeCheck: boolean
}

const initialState: EulaState = {
    isEulaCheck: false,
    isAgeCheck: false,
}

export const eula = createSlice({
    name: "eula",
    initialState,
    reducers: {
        setIsEulaCheck(state, action: PayloadAction<boolean>) {
            state.isEulaCheck = action.payload
        },

        setIsAgeCheck(state, action: PayloadAction<boolean>) {
            state.isAgeCheck = action.payload
        },
    },
})

export const eulaActions = eula.actions
export default eula.reducer
