import "./eula.css"
import React from "react"
import {useNavigate} from "react-router-dom"
import {useAppDispatch, useAppSelector} from "../../module/hook"
import {eulaActions} from "../../module/eula"
import {Header} from "../header"
import {Sidebar} from "../sidebar"

export function Eula() {
    return (
        <div className="Eula">
            <Body/>
        </div>
    )
}

function Body() {
    return (
        <div className={"Eula-Body"}>
            <Header/>
            <Sidebar/>
            <Title/>
            <EulaCheck/>
            <AgeCheck/>
            <Continue/>
        </div>
    )
}

function Title() {
    return (
        <div className="Eula-Title">
            <h1>서비스 약관 동의</h1>
            <p>환영합니다. Giggles 입니다. 이용약관 및 개인정보처리방침에 동의하시면 서비스 이용이 가능합니다.</p>
        </div>
    )
}

function EulaCheck() {
    const isEulaCheck = useAppSelector((state) => state.eula.isEulaCheck)

    const dispatch = useAppDispatch()

    const onChange = (e: any) => {
        if (isEulaCheck === false) {
            dispatch(eulaActions.setIsEulaCheck(true))
        } else {
            dispatch(eulaActions.setIsEulaCheck(false))
        }
    }

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, "_blank", "noopener,noreferrer")

        if (newWindow === null) {
            newWindow!.opener = null
        }
    }

    return (
        <div className="Eula-EulaCheck">
            <input
                type="checkbox"
                checked={isEulaCheck}
                onChange={onChange}
            />
            <p>[필수] 서비스 이용약관 동의</p>
            <button onClick={() => openInNewTab("https://sites.google.com/view/giggles-expert")}>
                보기
            </button>
        </div>
    )
}

function AgeCheck() {
    const isAgeCheck = useAppSelector((state) => state.eula.isAgeCheck)

    const dispatch = useAppDispatch()

    const onChange = (e: any) => {
        if (isAgeCheck === false) {
            dispatch(eulaActions.setIsAgeCheck(true))
        } else {
            dispatch(eulaActions.setIsAgeCheck(false))
        }
    }

    const openInNewTab = (url: any) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer')

        if (newWindow === null) {
            newWindow!.opener = null
        }
    }

    return (
        <div className="Eula-AgeCheck">
            <input
                type="checkbox"
                checked={isAgeCheck}
                onChange={onChange}
            />
            <p>[필수] 개인정보처리방침 동의</p>
            <button onClick={() => openInNewTab("https://sites.google.com/view/giggles-expert")}>
                보기
            </button>
        </div>
    )
}

function Continue() {
    const isEulaCheck = useAppSelector((state) => state.eula.isEulaCheck)
    const isAgeCheck = useAppSelector((state) => state.eula.isAgeCheck)

    const navigate = useNavigate()

    const onClick = () => {
        if (isEulaCheck && isAgeCheck) {
            navigate("/sign_up")
        } else {
            alert("필수 약관에 모두 동의하셔야 서비스 이용이 가능합니다.")
        }
    }

    return (
        <div className="Eula-Continue">
            <button onClick={onClick}>
                계속하기
            </button>
        </div>
    )
}
