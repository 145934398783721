import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface WriteState {
    content: string
    hashtags: string[]
}

const initialState: WriteState = {
    content: "",
    hashtags: [],
}

export const write = createSlice({
    name: "write",
    initialState,
    reducers: {
        setContent(state, action: PayloadAction<string>) {
            state.content = action.payload
        },

        setHashtags(state, action: PayloadAction<string[]>) {
            state.hashtags = action.payload
        },
    },
})

export const writeActions = write.actions
export default write.reducer
