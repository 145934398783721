import React from "react"
import "./App.css"
import {isMobile} from "react-device-detect"
import {BrowserRouter, Route, Routes} from "react-router-dom"
import {Home} from "./desktop/home/home"
import {Write} from "./desktop/write/write"
import {Provider} from "react-redux"
import {store} from "./module"
import "./firebase"
import {Login} from "./desktop/login/login"
import {Profile} from "./desktop/profile/profile"
import {MobileLogin} from "./mobile/login/mobile_login"
import {Eula} from "./desktop/eula/eula"
import {MobileEula} from "./mobile/eula/mobile_eula"
import {Read} from "./desktop/read/read"

function App() {
    if (!isMobile) {
        return (
            <BrowserRouter>
                <div className="App">
                    <Provider store={store}>
                        <Routes>
                            <Route path="/" element={<Home/>}/>
                            <Route path="/read/:slug" element={<Read/>}/>
                            <Route path="/login" element={<Login/>}/>
                            <Route path="/eula" element={<Eula/>}/>
                            <Route path="/profile" element={<Profile/>}/>
                            <Route path="/write" element={<Write/>}/>
                        </Routes>
                    </Provider>
                </div>
            </BrowserRouter>
        )
    } else {
        return (
            <BrowserRouter>
                <div className="App">
                    <Provider store={store}>
                        <Routes>
                            <Route path="/login" element={<MobileLogin/>}/>
                            <Route path="/eula" element={<MobileEula/>}/>
                        </Routes>
                    </Provider>
                </div>
            </BrowserRouter>
        )
    }
}

export default App;
