import {useNavigate, useParams} from "react-router-dom"
import React, {useEffect, useMemo, useState} from "react"
import {
    collection,
    deleteDoc,
    doc,
    getDoc,
    getFirestore,
    increment,
    limit,
    onSnapshot,
    orderBy,
    query,
    setDoc,
    startAfter,
    updateDoc
} from "firebase/firestore"
import {Sidebar} from "../sidebar"
import {getAuth} from "firebase/auth"
import "./read.css"
import {useBottomScrollListener} from "react-bottom-scroll-listener"
import moment from "moment/moment"
import parse from "html-react-parser"
import {DocumentState} from "../../module/document"
import {readActions} from "../../module/read"
import {CommentState} from "../../module/comment"
import {useAppDispatch, useAppSelector} from "../../module/hook"
import {Header} from "../header"
import defaultAvatar from "../../asset/image/default_avatar.png"
import heart from "../../asset/image/heart.png"
import {Loading} from "../loading/loading"
import {coreActions} from "../../module/core"
import uuid from "react-uuid"
import threeDots from "../../asset/image/three_dots.png"
import comment from "../../asset/image/comment.png";
import bookmark from "../../asset/image/bookmark.png";

export function Read() {
    const { slug } = useParams()

    const firestore = getFirestore()

    const isLoading = useAppSelector((state) => state.core.isLoading)
    const document = useAppSelector((state) => state.read.document)
    const comments = useAppSelector((state) => state.read.comments)
    const documents = useAppSelector((state) => state.read.documents)

    const dispatch = useAppDispatch()

    const [commentLastVisible, setCommentLastVisible] = useState<any>(undefined)
    const [documentImages, setDocumentImages] = useState(new Map())
    const [documentsLastVisible, setDocumentsLastVisible] = useState<any>(undefined)

    const getComments = () => {
        if (slug! !== undefined) {
            let q
            if (commentLastVisible === -1) {
                return
            } else if (commentLastVisible !== undefined) {
                q = query(collection(firestore, "board", slug!, "comment"), orderBy("timestamp", "desc"), limit(10), startAfter(commentLastVisible))
            } else {
                dispatch(readActions.resetComments())
                q = query(collection(firestore, "board", slug!, "comment"), orderBy("timestamp", "desc"), limit(10))
            }

            onSnapshot(q, async (snapshot) => {
                const newComments = snapshot.docs.map((elem) => elem.data())

                for (const elem of newComments) {
                    let authorAvatarUrl = ""
                    let authorNickname = ""

                    const docRef = doc(firestore, "user", elem.authorUid)

                    const docSnap = await getDoc(docRef)
                    if (docSnap.exists()) {
                        const data = docSnap.data()
                        authorAvatarUrl = data.avatarUrl || ""
                        authorNickname = data.nickname || ""
                    }

                    const comment: CommentState = {
                        commentId: elem.commentId,
                        authorUid: elem.authorUid,
                        authorAvatarUrl: authorAvatarUrl,
                        authorNickname: authorNickname,
                        timestamp: elem.timestamp,
                        content: elem.content,
                        numUps: 0,
                        numDowns: 0,
                    }

                    dispatch(readActions.appendComment(comment))
                }

                if (snapshot.docs.length === 0) {
                    setCommentLastVisible(-1)
                } else {
                    setCommentLastVisible(snapshot.docs[snapshot.docs.length - 1])
                }
            })
        }
    }

    async function reactiveLength(content: any) {
        if (Array.isArray(content) === true) {
            let length = 0
            for (let child of content) {
                if (Array.isArray(child.props.children)) {
                    length += await reactiveLength(child)
                } else {
                    if (child.props.children && child.props.children.type === "img") {
                        let img
                        if (documentImages.has(child.props.children.props.src)) {
                            img = documentImages.get(child.props.children.props.src)
                        } else {
                            img = new Image()

                            img.src = child.props.children.props.src
                            await img.decode()

                            documentImages.set(child.props.children.props.src, img)
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight
                        } else {
                            length += 1
                        }
                    } else if (child.props.children && child.props.children.length) {
                        length += child.props.children.length
                    } else {
                        length += 1
                    }
                }
            }

            setDocumentImages(documentImages)
            return length
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") && Array.isArray(content.props.children)) {
            let length = 0
            for (let child of content.props.children) {
                if (child.hasOwnProperty("props") && child.props.hasOwnProperty("children") && Array.isArray(child.props.children)) {
                    length += await reactiveLength(child)
                } else {
                    if (child.type === "img") {
                        let img
                        if (documentImages.has(child.props.src)) {
                            img = documentImages.get(child.props.src)
                        } else {
                            img = new Image()

                            img.src = child.props.src
                            await img.decode()

                            documentImages.set(child.props.src, img)
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight
                        } else {
                            length += 1
                        }
                    }

                    length += 1
                }
            }

            setDocumentImages(documentImages)
            return length
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") && content.props.children && content.props.children.type == "img") {
            let img
            if (documentImages.has(content.props.children.props.src)) {
                img = documentImages.get(content.props.children.props.src)
            } else {
                img = new Image()

                img.src = content.props.children.props.src
                await img.decode()

                documentImages.set(content.props.children.props.src, img)
                setDocumentImages(documentImages)
            }

            if (img.naturalHeight) {
                return img.naturalHeight
            } else {
                return 1
            }
        } else {
            return 1
        }
    }

    async function extractContent(content: any, pos: number, accum: number): Promise<any> {
        if (Array.isArray(content) === true) {
            for (let i = 0; i < content.length; i++) {
                const child = content[i]

                if (accum <= pos && accum + await reactiveLength(child) > pos) {
                    return extractContent(child, pos, accum)
                } else if (accum > pos) {
                    return
                } else {
                    accum += await reactiveLength(child)
                }
            }
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children) === true) {
            for (let i = 0; i < content.props.children.length; i++) {
                const child = content.props.children[i]

                if (accum <= pos && accum + await reactiveLength(child) > pos) {
                    return extractContent(child, pos, accum)
                } else if (accum > pos) {
                    return
                } else {
                    accum += await reactiveLength(child)
                }
            }
        } else {
            if (pos === accum) {
                return content
            }
        }
    }

    const getDocuments = async () => {
        let q
        if (documentsLastVisible === -1) {
            return
        } else if (documentsLastVisible !== undefined) {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10), startAfter(documentsLastVisible))
        } else {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10))
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data())

            for (const elem of newDocuments) {
                let authorAvatarUrl = ""
                let authorNickname = ""

                const docRef = doc(firestore, "user", elem.authorUid)

                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    const data = docSnap.data()
                    authorAvatarUrl = data.avatarUrl || ""
                    authorNickname = data.nickname || ""
                }

                const parsedContent = parse(elem.content)

                const lengthLimit = 700
                const length = await reactiveLength(parsedContent)

                let thumbnailContent: any[] = []
                let thumbnailImageSizes: any[] = []

                if (length >= 100) {
                    let index = 0
                    let extractLength = 0

                    let batchedContent = []

                    while (index < 5 && extractLength < lengthLimit) {
                        let ec = await extractContent(parsedContent, index, 0)

                        if (ec && ec.props !== undefined && ec.props.children !== undefined) {
                            if (ec.props.children.type === "img") {
                                let img
                                if (documentImages.has(ec.props.children.props.src)) {
                                    img = documentImages.get(ec.props.children.props.src)
                                } else {
                                    img = new Image()

                                    img.src = ec.props.children.props.src
                                    await img.decode()

                                    documentImages.set(ec.props.children.props.src, img)
                                    setDocumentImages(documentImages)
                                }

                                if (img.naturalHeight) {
                                    if (extractLength + img.naturalHeight > lengthLimit) {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: lengthLimit - extractLength,
                                            },
                                        ]

                                        extractLength += lengthLimit - extractLength
                                    } else {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: img.naturalHeight,
                                            },
                                        ]

                                        extractLength += img.naturalHeight
                                    }
                                } else {
                                    extractLength += 1
                                }
                            } else if (ec.props.children.length) {
                                extractLength += ec.props.children.length
                            } else {
                                extractLength += 1
                            }

                            batchedContent.push(ec)
                        } else if (ec && ec.type == "img") {
                            let img
                            if (documentImages.has(ec.props.src)) {
                                img = documentImages.get(ec.props.src)
                            } else {
                                img = new Image()

                                img.src = ec.props.src
                                await img.decode()

                                documentImages.set(ec.props.src, img)
                                setDocumentImages(documentImages)
                            }

                            if (img.naturalHeight) {
                                if (extractLength + img.naturalHeight > lengthLimit) {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: lengthLimit - extractLength,
                                        },
                                    ]

                                    extractLength += lengthLimit - extractLength
                                } else {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: img.naturalHeight,
                                        },
                                    ]

                                    extractLength += img.naturalHeight
                                }
                            } else {
                                extractLength += 1
                            }

                            batchedContent.push(ec)
                        } else if (ec) {
                            extractLength += 1

                            batchedContent.push(ec)
                        } else {
                            extractLength += 1
                        }

                        index += 1
                    }

                    thumbnailContent = batchedContent
                } else {
                    thumbnailContent = [parsedContent]
                }

                const document: DocumentState = {
                    documentId: elem.documentId,
                    authorUid: elem.authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: elem.timestamp,
                    content: elem.content,
                    thumbnailContent: thumbnailContent,
                    thumbnailImageSizes: thumbnailImageSizes,
                    hashtags: elem.hashtags.split(","),
                    numUps: 0,
                    numDowns: 0,
                }

                dispatch(readActions.appendDocument(document))
            }

            if (snapshot.docs.length === 0) {
                setDocumentsLastVisible(-1)
            } else {
                setDocumentsLastVisible(snapshot.docs[snapshot.docs.length - 1])
            }
        })
    }

    useBottomScrollListener(getDocuments)

    useEffect(() => {
        dispatch(readActions.resetDocument())

        const docRef = doc(firestore, "board", slug!)
        getDoc(docRef).then(async (docSnap) => {
            let authorAvatarUrl = ""
            let authorNickname = ""

            const userDocRef = doc(firestore, "user", docSnap.data()!.authorUid)

            await getDoc(userDocRef).then((userDocSnap) => {
                if (userDocSnap.exists()) {
                    const data = userDocSnap.data()

                    authorAvatarUrl = data.avatarUrl || ""
                    authorNickname = data.nickname || ""
                }
            })

            const document: DocumentState = {
                documentId: docSnap.data()!.documentId,
                authorUid: docSnap.data()!.authorUid,
                authorAvatarUrl: authorAvatarUrl,
                authorNickname: authorNickname,
                timestamp: docSnap.data()!.timestamp,
                content: docSnap.data()!.content,
                thumbnailContent: [],
                thumbnailImageSizes: [],
                hashtags: docSnap.data()!.hashtags.split(","),
                numUps: 0,
                numDowns: 0,
            }

            dispatch(readActions.setDocument(document))
        })

        setCommentLastVisible(undefined)
        setDocumentsLastVisible(undefined)

        getComments()
        getDocuments()
    }, [])

    const toolbarOptions = useMemo(() => ({
        toolbar: null
    }), [])

    return (
        <div className="Read">
            {
                isLoading ?
                    <Loading/> :
                    <Main document={document} comments={comments} documents={documents}/>
            }
        </div>
    )
}

function Main(props: {document: DocumentState, comments: CommentState[], documents: DocumentState[]}) {
    return (
        <div className="Read-Main">
            <Header/>
            <Sidebar/>
            <Body document={props.document} comments={props.comments} documents={props.documents}/>
        </div>
    )
}


function Body(props: {document: DocumentState, comments: CommentState[], documents: DocumentState[]}) {
    return (
        <div className="Read-Body">
            <Document document={props.document}/>
            <CommentInput document={props.document}/>
            <Comments document={props.document} comments={props.comments}/>
        </div>
    )
}

function Document(props: {document: DocumentState}) {
    return (
        <div className="Read-Document">
            <DocumentHeader document={props.document}/>
            <Content document={props.document}/>
            <Summary document={props.document}/>
        </div>
    )
}

function DocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="Read-DocumentHeader">
            <Avatar document={props.document}/>
            <Nickname document={props.document}/>
            <Time document={props.document}/>
            <Bookmark document={props.document}/>
            <Report document={props.document}/>
        </div>
    )
}

function Avatar(props: {document: DocumentState}) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.document.authorAvatarUrl !== "") {
        return (
            <div className="Read-Avatar">
                <img src={props.document.authorAvatarUrl} onClick={onClick}/>
            </div>
        )
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        )
    }
}

function Nickname(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.document.authorNickname !== "") {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    {props.document.authorNickname}
                </button>
            </div>
        )
    } else {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        )
    }
}

function Time(props: { document: DocumentState }) {
    const endTimestamp = Date.now()

    const beginDate = moment(props.document.timestamp)
    const endDate = moment(endTimestamp)
    const diff = endDate.diff(beginDate)
    const diffDuration = moment.duration(diff)

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Read-Time">
                <p>방금 전</p>
            </div>
        )
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        )
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        )
    } else {
        return (
            <div className="Read-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        )
    }
}

function Bookmark(props: {document: DocumentState}) {
    const navigate = useNavigate()

    const [click, setClick] = useState(false)

    const auth = getAuth()

    useEffect(() => {
        if (props.document.documentId !== "" && auth.currentUser !== null) {
            const firestore = getFirestore()

            const bookmarkDocRef = doc(firestore, "user", auth.currentUser.uid, "bookmark", props.document.documentId)
            getDoc(bookmarkDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setClick(true)
                }
            })
        }
    }, [])

    if (props.document.documentId !== "" && auth.currentUser !== null) {
        const firestore = getFirestore()

        const bookmarkDocRef = doc(firestore, "user", auth.currentUser.uid, "bookmark", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setClick(false)

                await deleteDoc(bookmarkDocRef)
            } else {
                setClick(true)

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    "documentId": props.document.documentId,
                    "uid": auth.currentUser!.uid,
                    "timestamp": timestamp,
                });
            }
        };

        if (click === true) {
            return (
                <div className="Read-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Read-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Read-Bookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        )
    }
}

function Report(props: {document: DocumentState}) {
    const onClick = () => {
    }

    return (
        <div className="Read-Report">
            <button onClick={onClick}>
                <img src={threeDots}/>
            </button>
        </div>
    )
}

function Content(props: {document: DocumentState}) {
    const parsedContent = parse(props.document.content)

    return (
        <div className="Read-Content">
            {parsedContent}
        </div>
    )
}

function Summary(props: { document: DocumentState }) {
    return (
        <div className="Read-Summary">
            <NumUps document={props.document}/>
            <NumDowns document={props.document}/>
            <NumComments document={props.document}/>
            <NumTokens document={props.document}/>
        </div>
    )
}

function NumUps(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const [numUps, setNumUps] = useState(props.document.numUps)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        if (props.document.documentId !== "") {
            const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
            getDoc(summaryDocRef).then((docSnap) => {
                if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                    setNumUps(docSnap.data()["numUps"])
                }
            })
        }
    }, [])

    if (props.document.documentId !== "" && auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "up", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                })
            } else {
                setNumUps(numUps + 1)
                setClick(true)

                const upTimestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": upTimestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Read-NumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Read-NumUps">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numUps}</p>
                </button>
            </div>
        )
    }
}

function NumDowns(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const [numDowns, setNumDowns] = useState(props.document.numDowns)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        if (props.document.documentId !== "") {
            const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
            getDoc(summaryDocRef).then((docSnap) => {
                if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                    setNumDowns(docSnap.data()["numDowns"])
                }
            })
        }
    }, [])

    if (props.document.documentId !== "" && auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "up", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setNumDowns(numDowns - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                })
            } else {
                setNumDowns(numDowns + 1)
                setClick(true)

                const upTimestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": upTimestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Read-NumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Read-NumDowns">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        )
    }
}

function NumComments(props: {document: DocumentState}) {
    const navigate = useNavigate()

    const [numComments, setNumComments] = useState(0)

    const firestore = getFirestore()

    useEffect(() => {
        if (props.document.documentId !== "") {
            const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
            getDoc(summaryDocRef).then((docSnap) => {
                if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
                    setNumComments(docSnap.data()["numComments"])
                }
            })
        }
    }, [])

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`)
    }

    return (
        <div className="Read-NumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function NumTokens(props: {document: DocumentState}) {
    const [numTokens, setNumTokens] = useState(0)

    return (
        <div className="Read-NumTokens">
            <button>
                <img src={comment}/>
                <p>{numTokens}</p>
            </button>
        </div>
    );
}

function CommentInput(props: { document: DocumentState }) {
    return (
        <div className="Read-CommentInput">
            <CommentInputAvatar/>
            <CommentInputBody document={props.document}/>
        </div>
    )
}

function CommentInputAvatar() {
    const [avatarUrl, setAvatarUrl] = useState("")

    useEffect(() => {
        const auth = getAuth()

        const firestore = getFirestore()

        if (auth.currentUser !== null) {
            const docRef = doc(firestore, "user", auth.currentUser.uid)

            getDoc(docRef).then((docSnap) => {
                if (docSnap.exists()) {
                    const data = docSnap.data()

                    setAvatarUrl(data.avatarUrl || "")
                }
            })
        }
    }, [])

    if (avatarUrl !== "") {
        return (
            <div className="Read-CommentAvatar">
                <img src={avatarUrl}/>
            </div>
        )
    } else {
        return (
            <div className="Read-CommentAvatar">
                <img src={defaultAvatar}/>
            </div>
        )
    }
}

function CommentInputBody(props: {document: DocumentState}) {
    const dispatch = useAppDispatch()

    const [content, setContent] = useState("")

    const onChange = (e: any) => {
        const {target: {name, value}} = e

        setContent(value)
    }

    const onClick = async () => {
        if (content !== "") {
            dispatch(coreActions.setIsLoading(true))

            const auth = getAuth()
            const firestore = getFirestore()

            const timestamp = Date.now()

            const id = uuid()

            const commentId = timestamp.toString() + "-" + id

            if (auth.currentUser !== null) {
                await updateDoc(doc(firestore, "metadata", props.document.documentId), {
                    "numComments": increment(1),
                })

                await setDoc(doc(firestore, "board", props.document.documentId, "comment", commentId), {
                    "documentId": props.document.documentId,
                    "commentId": commentId,
                    "authorUid": auth.currentUser.uid,
                    "content": content,
                    "timestamp": timestamp,
                })
            } else {
                alert("댓글 쓰기는 로그인하여야 가능합니다.")
            }

            setContent("")

            dispatch(coreActions.setIsLoading(false))
        }
    }

    return (
        <div className="Read-CommentInputBody">
            <form>
                <textarea
                    placeholder = {"댓글을 남겨주세요!"}
                    wrap = "soft"
                    value={content}
                    onChange={onChange}/>
            </form>
            <button onClick={onClick}>
                등록
            </button>
        </div>
    )
}

function Comments(props: {document: DocumentState, comments: CommentState[]}) {
    const comments = props.comments.map((item, i) => {
        return <Comment document={props.document} comment={item}/>
    })

    return (
        <div className="Read-Comments">
            {comments}
        </div>
    )
}

function Comment(props: {document: DocumentState, comment: CommentState}) {
    return (
        <div className="Read-Comment">
            <CommentHeader comment={props.comment}/>
            <CommentBody comment={props.comment}/>
            <CommentSummary document={props.document} comment={props.comment}/>
        </div>
    )
}

function CommentHeader(props: {comment: CommentState}) {
    return (
        <div className="Read-CommentHeader">
            <CommentAvatar comment={props.comment}/>
            <CommentNickname comment={props.comment}/>
            <CommentTime comment={props.comment}/>
            <CommentReport comment={props.comment}/>
        </div>
    )
}

function CommentAvatar(props: {comment: CommentState}) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.comment.authorAvatarUrl !== "") {
        return (
            <div className="Read-Avatar">
                <img src={props.comment.authorAvatarUrl} onClick={onClick}/>
            </div>
        )
    } else {
        return (
            <div className="Read-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        )
    }
}

function CommentTime(props: { comment: CommentState }) {
    const endTimestamp = Date.now()

    const beginDate = moment(props.comment.timestamp)
    const endDate = moment(endTimestamp)
    const diff = endDate.diff(beginDate)
    const diffDuration = moment.duration(diff)

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Read-Time">
                <p>방금 전</p>
            </div>
        )
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        )
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Read-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        )
    } else {
        return (
            <div className="Read-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        )
    }
}

function CommentNickname(props: { comment: CommentState }) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.comment.authorNickname !== "") {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    {props.comment.authorNickname}
                </button>
            </div>
        )
    } else {
        return (
            <div className="Read-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        )
    }
}

function CommentReport(props: {comment: CommentState}) {
    const onClick = () => {
    }

    return (
        <div className="Read-CommentReport">
            <button onClick={onClick}>
                <img src={threeDots}/>
            </button>
        </div>
    )
}

function CommentBody(props: {comment: CommentState}) {
    return (
        <div className="Read-CommentBody">
            <p>
                {props.comment.content}
            </p>
        </div>
    )
}

function CommentSummary(props: {document: DocumentState, comment: CommentState}) {
    return (
        <div className="Read-CommentSummary">
            <CommentNumUps document={props.document} comment={props.comment}/>
            <CommentNumDowns document={props.document} comment={props.comment}/>
        </div>
    )
}

function CommentNumUps(props: {document: DocumentState, comment: CommentState}) {
    const navigate = useNavigate()

    const [numUps, setNumUps] = useState(0)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        if (props.document.documentId !== "") {
            const summaryDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId)
            getDoc(summaryDocRef).then((docSnap) => {
                if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                    setNumUps(docSnap.data()["numUps"])
                }
            })
        }
    }, [])

    if (props.document.documentId !== "" && auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId, "up", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId)

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                })
            } else {
                setNumUps(numUps + 1)
                setClick(true)

                const timestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": timestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Read-CommentNumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Read-CommentNumUps">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numUps}</p>
                </button>
            </div>
        )
    }
}

function CommentNumDowns(props: {document: DocumentState, comment: CommentState}) {
    const navigate = useNavigate()

    const [numDowns, setNumDowns] = useState(0)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        if (props.document.documentId !== "") {
            const summaryDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId)
            getDoc(summaryDocRef).then((docSnap) => {
                if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                    setNumDowns(docSnap.data()["numDowns"])
                }
            })
        }
    }, [])

    if (props.document.documentId !== "" && auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId, "down", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId, "comment", props.comment.commentId)

        const onClick = async () => {
            if (click === true) {
                setNumDowns(numDowns - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                })
            } else {
                setNumDowns(numDowns + 1)
                setClick(true)

                const timestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": timestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Read-CommentNumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Read-CommentNumDowns">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        )
    }
}
