import logo from "../asset/image/logo.png"
import menu from "../asset/image/menu.png"
import {useNavigate} from "react-router-dom"
import "./header.css"

export function Header() {
    return (
        <div className="Header">
            <Logo/>
            <Navigation/>
            <Action/>
        </div>
    )
}

function Logo() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/")
    };

    return (
        <div className="Header-Logo">
            <img src={logo} onClick={onClick}/>
        </div>
    );
}

function Navigation() {
    return (
        <div className="Header-Navigation">
            <Recommendation/>
            <Following/>
            <Like/>
        </div>
    )
}

function Recommendation() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/")
    };

    return (
        <div className="Header-Recommendation">
            <button onClick={onClick}>
                추천
            </button>
        </div>
    );
}

function Following() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/following")
    };

    return (
        <div className="Header-Following">
            <button onClick={onClick}>
                팔로잉
            </button>
        </div>
    );
}

function Like() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/like")
    };

    return (
        <div className="Header-Following">
            <button onClick={onClick}>
                좋아요
            </button>
        </div>
    );
}

function Action() {
    return (
        <div className="Header-Action">
            <Menu/>
            <Login/>
        </div>
    );
}

function Menu() {
    return (
        <div className="Header-Menu">
            <img src={menu}/>
        </div>
    );
}

function Login() {
    const navigate = useNavigate();

    const onClick = () => {
        navigate("/login")
    };

    return (
        <div className="Header-Login">
            <button onClick={onClick}>
                로그인
            </button>
        </div>
    );
}
