import {DocumentState} from "../../module/document"
import {homeActions} from "../../module/home"
import {useAppDispatch, useAppSelector} from "../../module/hook"
import {
    collection,
    doc,
    getDoc,
    getFirestore,
    limit,
    onSnapshot,
    orderBy,
    query,
    startAfter,
    deleteDoc,
    updateDoc,
    setDoc,
    increment,
} from "firebase/firestore"
import {useNavigate} from "react-router-dom"
import heart from "../../asset/image/heart.png"
import defaultAvatar from "../../asset/image/default_avatar.png"
import {useBottomScrollListener} from "react-bottom-scroll-listener"
import React, {useEffect, useState} from "react"
import "./home.css"
import {Header} from "../header"
import {Sidebar} from "../sidebar"
import moment from "moment"
import parse from "html-react-parser"
import {getAuth} from "firebase/auth"
import threeDots from "../../asset/image/three_dots.png"
import comment from "../../asset/image/comment.png"
import bookmark from "../../asset/image/bookmark.png"

export function Home() {
    const firestore = getFirestore()

    const documents = useAppSelector((state) => state.home.documents)

    const dispatch = useAppDispatch()

    const [images, setImages] = useState(new Map())
    const [lastVisible, setLastVisible] = useState<any>(undefined)

    async function reactiveLength(content: any) {
        if (Array.isArray(content) === true) {
            let length = 0
            for (let child of content) {
                if (Array.isArray(child.props.children)) {
                    length += await reactiveLength(child)
                } else {
                    if (child.props.children && child.props.children.type === "img") {
                        let img
                        if (images.has(child.props.children.props.src)) {
                            img = images.get(child.props.children.props.src)
                        } else {
                            img = new Image()

                            img.src = child.props.children.props.src
                            await img.decode()

                            images.set(child.props.children.props.src, img)
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight
                        } else {
                            length += 1
                        }
                    } else if (child.props.children && child.props.children.length) {
                        length += child.props.children.length
                    } else {
                        length += 1
                    }
                }
            }

            setImages(images)
            return length
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") && Array.isArray(content.props.children)) {
            let length = 0
            for (let child of content.props.children) {
                if (child.hasOwnProperty("props") && child.props.hasOwnProperty("children") && Array.isArray(child.props.children)) {
                    length += await reactiveLength(child)
                } else {
                    if (child.type === "img") {
                        let img
                        if (images.has(child.props.src)) {
                            img = images.get(child.props.src)
                        } else {
                            img = new Image()

                            img.src = child.props.src
                            await img.decode()

                            images.set(child.props.src, img)
                        }

                        if (img.naturalHeight) {
                            length += img.naturalHeight
                        } else {
                            length += 1
                        }
                    }

                    length += 1
                }
            }

            setImages(images)
            return length
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") && content.props.children && content.props.children.type == "img") {
            let img
            if (images.has(content.props.children.props.src)) {
                img = images.get(content.props.children.props.src)
            } else {
                img = new Image()

                img.src = content.props.children.props.src
                await img.decode()

                images.set(content.props.children.props.src, img)
                setImages(images)
            }

            if (img.naturalHeight) {
                return img.naturalHeight
            } else {
                return 1
            }
        } else {
            return 1
        }
    }

    async function extractContent(content: any, pos: number, accum: number): Promise<any> {
        if (Array.isArray(content) === true) {
            for (let i = 0; i < content.length; i++) {
                const child = content[i]

                if (accum <= pos && accum + await reactiveLength(child) > pos) {
                    return extractContent(child, pos, accum)
                } else if (accum > pos) {
                    return
                } else {
                    accum += await reactiveLength(child)
                }
            }
        } else if (content.hasOwnProperty("props") && content.props.hasOwnProperty("children") &&
            Array.isArray(content.props.children) === true) {
            for (let i = 0; i < content.props.children.length; i++) {
                const child = content.props.children[i]

                if (accum <= pos && accum + await reactiveLength(child) > pos) {
                    return extractContent(child, pos, accum)
                } else if (accum > pos) {
                    return
                } else {
                    accum += await reactiveLength(child)
                }
            }
        } else {
            if (pos === accum) {
                return content
            }
        }
    }

    const getDocuments = async () => {
        let q
        if (lastVisible === -1) {
            return
        } else if (lastVisible !== undefined) {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10), startAfter(lastVisible))
        } else {
            q = query(collection(firestore, "board"), orderBy("timestamp", "desc"), limit(10))
        }

        onSnapshot(q, async (snapshot) => {
            const newDocuments = snapshot.docs.map((elem) => elem.data())

            for (const elem of newDocuments) {
                let authorAvatarUrl = ""
                let authorNickname = ""

                const docRef = doc(firestore, "user", elem.authorUid)

                const docSnap = await getDoc(docRef)
                if (docSnap.exists()) {
                    const data = docSnap.data()
                    authorAvatarUrl = data.avatarUrl || ""
                    authorNickname = data.nickname || ""
                }

                const parsedContent = parse(elem.content)

                const lengthLimit = 700
                const length = await reactiveLength(parsedContent)

                let thumbnailContent: any[] = []
                let thumbnailImageSizes: any[] = []

                if (length >= 100) {
                    let index = 0
                    let extractLength = 0

                    let batchedContent = []

                    while (index < 5 && extractLength < lengthLimit) {
                        let ec = await extractContent(parsedContent, index, 0)

                        if (ec && ec.props !== undefined && ec.props.children !== undefined) {
                            if (ec.props.children.type === "img") {
                                let img
                                if (images.has(ec.props.children.props.src)) {
                                    img = images.get(ec.props.children.props.src)
                                } else {
                                    img = new Image()

                                    img.src = ec.props.children.props.src
                                    await img.decode()

                                    images.set(ec.props.children.props.src, img)
                                    setImages(images)
                                }

                                if (img.naturalHeight) {
                                    if (extractLength + img.naturalHeight > lengthLimit) {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: lengthLimit - extractLength,
                                            },
                                        ]

                                        extractLength += lengthLimit - extractLength
                                    } else {
                                        thumbnailImageSizes = [
                                            ...thumbnailImageSizes,
                                            {
                                                src: ec.props.children.props.src,
                                                width: img.naturalWidth,
                                                height: img.naturalHeight,
                                            },
                                        ]

                                        extractLength += img.naturalHeight
                                    }
                                } else {
                                    extractLength += 1
                                }
                            } else if (ec.props.children.length) {
                                extractLength += ec.props.children.length
                            } else {
                                extractLength += 1
                            }

                            batchedContent.push(ec)
                        } else if (ec && ec.type == "img") {
                            let img
                            if (images.has(ec.props.src)) {
                                img = images.get(ec.props.src)
                            } else {
                                img = new Image()

                                img.src = ec.props.src
                                await img.decode()

                                images.set(ec.props.src, img)
                                setImages(images)
                            }

                            if (img.naturalHeight) {
                                if (extractLength + img.naturalHeight > lengthLimit) {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: lengthLimit - extractLength,
                                        },
                                    ]

                                    extractLength += lengthLimit - extractLength
                                } else {
                                    thumbnailImageSizes = [
                                        ...thumbnailImageSizes,
                                        {
                                            src: ec.props.src,
                                            width: img.naturalWidth,
                                            height: img.naturalHeight,
                                        },
                                    ]

                                    extractLength += img.naturalHeight
                                }
                            } else {
                                extractLength += 1
                            }

                            batchedContent.push(ec)
                        } else if (ec) {
                            extractLength += 1

                            batchedContent.push(ec)
                        } else {
                            extractLength += 1
                        }

                        index += 1
                    }

                    thumbnailContent = batchedContent
                } else {
                    thumbnailContent = [parsedContent]
                }

                const document: DocumentState = {
                    documentId: elem.documentId,
                    authorUid: elem.authorUid,
                    authorAvatarUrl: authorAvatarUrl,
                    authorNickname: authorNickname,
                    timestamp: elem.timestamp,
                    content: elem.content,
                    thumbnailContent: thumbnailContent,
                    thumbnailImageSizes: thumbnailImageSizes,
                    hashtags: elem.hashtags.split(","),
                    numUps: 0,
                    numDowns: 0,
                }

                dispatch(homeActions.appendDocument(document))
            }

            if (snapshot.docs.length === 0) {
                setLastVisible(-1)
            } else {
                setLastVisible(snapshot.docs[snapshot.docs.length - 1])
            }
        })
    }

    useEffect(() => {
        setLastVisible(undefined)
        getDocuments()
    }, [])

    useBottomScrollListener(getDocuments)

    return (
        <div className="Home">
            <Body documents={documents}/>
        </div>
    )
}

function Body(props: { documents: DocumentState[] }) {
    return (
        <div className="Home-Body">
            <Header/>
            <Sidebar/>
            <Documents documents={props.documents}/>
        </div>
    )
}

function Documents(props: { documents: DocumentState[] }) {
    const documents = props.documents.map((item, i) => {
        return <Document document={item}/>
    })

    return (
        <div className="Home-Documents">
            {documents}
        </div>
    )
}

function Document(props: { document: DocumentState }) {
    return (
        <div className="Home-Document">
            <DocumentHeader document={props.document}/>
            <ThumbnailContent document={props.document}/>
            <Summary document={props.document}/>
        </div>
    )
}

function DocumentHeader(props: { document: DocumentState }) {
    return (
        <div className="Home-DocumentHeader">
            <Avatar document={props.document}/>
            <Nickname document={props.document}/>
            <Time document={props.document}/>
            <Bookmark document={props.document}/>
            <Report document={props.document}/>
        </div>
    )
}

function Avatar(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.document.authorAvatarUrl !== "") {
        return (
            <div className="Home-Avatar">
                <img src={props.document.authorAvatarUrl} onClick={onClick}/>
            </div>
        )
    } else {
        return (
            <div className="Home-Avatar">
                <img src={defaultAvatar} onClick={onClick}/>
            </div>
        )
    }
}

function Nickname(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const onClick = () => {
        navigate("/profile_view")
    }

    if (props.document.authorNickname !== "") {
        return (
            <div className="Home-Nickname">
                <button onClick={onClick}>
                    {props.document.authorNickname}
                </button>
            </div>
        )
    } else {
        return (
            <div className="Home-Nickname">
                <button onClick={onClick}>
                    닉네임 없음
                </button>
            </div>
        )
    }
}

function Time(props: { document: DocumentState }) {
    const endTimestamp = Date.now()

    const beginDate = moment(props.document.timestamp)
    const endDate = moment(endTimestamp)
    const diff = endDate.diff(beginDate)
    const diffDuration = moment.duration(diff)

    if (diffDuration.minutes() < 1) {
        return (
            <div className="Home-Time">
                <p>방금 전</p>
            </div>
        )
    } else if (diffDuration.hours() < 1) {
        return (
            <div className="Home-Time">
                <p>{diffDuration.minutes()} 분 전</p>
            </div>
        )
    } else if (diffDuration.days() < 1) {
        return (
            <div className="Home-Time">
                <p>{diffDuration.hours()} 시간 전</p>
            </div>
        )
    } else {
        return (
            <div className="Home-Time">
                <p>{beginDate.format("YYYY-MM-DD")}</p>
            </div>
        )
    }
}

function Bookmark(props: {document: DocumentState}) {
    const navigate = useNavigate()

    const [click, setClick] = useState(false)

    const auth = getAuth()

    useEffect(() => {
        if (auth.currentUser !== null) {
            const firestore = getFirestore()

            const bookmarkDocRef = doc(firestore, "user", auth.currentUser.uid, "bookmark", props.document.documentId)
            getDoc(bookmarkDocRef).then((docSnap) => {
                if (docSnap.exists()) {
                    setClick(true)
                }
            })
        }
    }, [])

    if (auth.currentUser !== null) {
        const firestore = getFirestore()

        const bookmarkDocRef = doc(firestore, "user", auth.currentUser.uid, "bookmark", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setClick(false)

                await deleteDoc(bookmarkDocRef)
            } else {
                setClick(true)

                const timestamp = Date.now();

                await setDoc(bookmarkDocRef, {
                    "documentId": props.document.documentId,
                    "uid": auth.currentUser!.uid,
                    "timestamp": timestamp,
                });
            }
        };

        if (click === true) {
            return (
                <div className="Home-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        } else {
            return (
                <div className="Home-Bookmark">
                    <button onClick={onClick}>
                        <img src={bookmark}/>
                    </button>
                </div>
            );
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Home-Bookmark">
                <button onClick={onClick}>
                    <img src={bookmark}/>
                </button>
            </div>
        )
    }
}

function Report(props: {document: DocumentState}) {
    const onClick = () => {
    }

    return (
        <div className="Home-Report">
            <button onClick={onClick}>
                <img src={threeDots}/>
            </button>
        </div>
    )
}

function ThumbnailContent(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions())

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`)
    }

    function getWindowDimensions() {
        const {innerWidth: width, innerHeight: height} = window
        return {
            width,
            height
        }
    }

    useEffect(() => {
        function handleResize() {
            setWindowDimensions(getWindowDimensions())
        }

        window.addEventListener("resize", handleResize)

        return () => window.removeEventListener("resize", handleResize)
    }, [])

    return (
        <div className="Home-ThumbnailContent">
            <button onClick={onClick}>
                {props.document.thumbnailContent.map((item, i) => {
                    if (item.props !== undefined && item.props.children !== undefined) {
                        if (item.props.children.type === "img") {
                            for (let imageSize of props.document.thumbnailImageSizes) {
                                if (imageSize.src == item.props.children.props.src) {
                                    if (0.99 * 0.44 * windowDimensions.width - 20 < imageSize.width) {
                                        return (
                                            <img
                                                style={{
                                                    width: 0.9 * 0.44 * windowDimensions.width - 20,
                                                    height: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%"
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        )
                                    } else {
                                        return (
                                            <img
                                                style={{
                                                    width: imageSize.width,
                                                    height: imageSize.height,
                                                    objectFit: "cover",
                                                    objectPosition: "0% 0%"
                                                }}
                                                src={item.props.children.props.src}
                                            />
                                        )
                                    }
                                }
                            }

                            return item
                        } else {
                            return item
                        }
                    } else if (item.type == "img" && item.props !== undefined) {
                        for (let imageSize of props.document.thumbnailImageSizes) {
                            if (imageSize.src == item.props.src) {
                                if (0.99 * 0.44 * windowDimensions.width - 20 < imageSize.width) {
                                    return (
                                        <img
                                            style={{
                                                width: 0.9 * 0.44 * windowDimensions.width - 20,
                                                height: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%"
                                            }}
                                            src={item.props.src}
                                        />
                                    )
                                } else {
                                    return (
                                        <img
                                            style={{
                                                width: imageSize.width,
                                                height: imageSize.height,
                                                objectFit: "cover",
                                                objectPosition: "0% 0%"
                                            }}
                                            src={item.props.src}
                                        />
                                    )
                                }
                            }
                        }

                        return item
                    } else {
                        return item
                    }
                })}
            </button>
        </div>
    )
}

function Summary(props: { document: DocumentState }) {
    return (
        <div className="Home-Summary">
            <NumUps document={props.document}/>
            <NumDowns document={props.document}/>
            <NumComments document={props.document}/>
            <NumTokens document={props.document}/>
        </div>
    )
}

function NumUps(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const [numUps, setNumUps] = useState(props.document.numUps)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numUps")) {
                setNumUps(docSnap.data()["numUps"])
            }
        })
    }, [])

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "up", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setNumUps(numUps - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numUps": increment(-1),
                })
            } else {
                setNumUps(numUps + 1)
                setClick(true)

                const upTimestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": upTimestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numUps": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Home-NumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Home-NumUps">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numUps}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Home-NumUps">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numUps}</p>
                </button>
            </div>
        )
    }
}

function NumDowns(props: { document: DocumentState }) {
    const navigate = useNavigate()

    const [numDowns, setNumDowns] = useState(props.document.numDowns)
    const [click, setClick] = useState(false)

    const auth = getAuth()

    const firestore = getFirestore()

    useEffect(() => {
        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
        getDoc(summaryDocRef).then((docSnap) => {
            if (docSnap.exists() && docSnap.data().hasOwnProperty("numDowns")) {
                setNumDowns(docSnap.data()["numDowns"])
            }
        })
    }, [])

    if (auth.currentUser !== null) {
        const metadataDocRef = doc(firestore, "metadata", props.document.documentId, "up", auth.currentUser.uid)
        getDoc(metadataDocRef).then((docSnap) => {
            if (docSnap.exists()) {
                setClick(true)
            }
        })

        const summaryDocRef = doc(firestore, "metadata", props.document.documentId)

        const onClick = async () => {
            if (click === true) {
                setNumDowns(numDowns - 1)
                setClick(false)

                await deleteDoc(metadataDocRef)

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(-1),
                })
            } else {
                setNumDowns(numDowns + 1)
                setClick(true)

                const upTimestamp = Date.now()

                await setDoc(metadataDocRef, {
                    "uid": auth.currentUser!.uid,
                    "timestamp": upTimestamp,
                })

                await updateDoc(summaryDocRef, {
                    "numDowns": increment(1),
                })
            }
        }

        if (click === true) {
            return (
                <div className="Home-NumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        } else {
            return (
                <div className="Home-NumDowns">
                    <button onClick={onClick}>
                        <img src={heart}/>
                        <p>{numDowns}</p>
                    </button>
                </div>
            )
        }
    } else {
        const onClick = () => {
            navigate("/login")
        }

        return (
            <div className="Home-NumDowns">
                <button onClick={onClick}>
                    <img src={heart}/>
                    <p>{numDowns}</p>
                </button>
            </div>
        )
    }
}

function NumComments(props: {document: DocumentState}) {
    const navigate = useNavigate()

    const [numComments, setNumComments] = useState(0)

    const firestore = getFirestore()

    const summaryDocRef = doc(firestore, "metadata", props.document.documentId)
    getDoc(summaryDocRef).then((docSnap) => {
        if (docSnap.exists() && docSnap.data().hasOwnProperty("numComments")) {
            setNumComments(docSnap.data()["numComments"])
        }
    })

    const onClick = () => {
        navigate(`/read/${props.document.documentId}`)
    }

    return (
        <div className="Home-NumComments">
            <button onClick={onClick}>
                <img src={comment}/>
                <p>{numComments}</p>
            </button>
        </div>
    );
}

function NumTokens(props: {document: DocumentState}) {
    const [numTokens, setNumTokens] = useState(0)

    return (
        <div className="Home-NumTokens">
            <button>
                <img src={comment}/>
                <p>{numTokens}</p>
            </button>
        </div>
    );
}
