import {createSlice, PayloadAction} from "@reduxjs/toolkit"
import {DocumentState} from "./document"
import {ReadState} from "./read";

export interface HomeState {
    documents: DocumentState[],
}

const initialState: HomeState = {
    documents: [],
}

export const home = createSlice({
    name: "home",
    initialState,
    reducers: {
        resetDocuments(state) {
            state.documents = []
        },

        appendDocument(state, action: PayloadAction<DocumentState>) {
            const isEqualTimestamp = (document: DocumentState) => document.timestamp == action.payload.timestamp

            if (state.documents.findIndex(isEqualTimestamp) >= 0) {
                return
            } else {
                state.documents = [
                    ...state.documents,
                    action.payload,
                ].sort((a, b) => a.timestamp < b.timestamp ? 1 : -1)
            }
        },
    },
})

export const homeActions = home.actions
export default home.reducer
