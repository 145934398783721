import {createSlice, PayloadAction} from "@reduxjs/toolkit"

export interface ProfileState {
    uid: string
    avatarUrl: string
    nickname: string
}

const initialState: ProfileState = {
    uid: "",
    avatarUrl: "",
    nickname: "",
}

export const profile = createSlice({
    name: "profile",
    initialState,
    reducers: {
        setUid(state, action: PayloadAction<string>) {
            state.uid = action.payload
        },

        setAvatarUrl(state, action: PayloadAction<string>) {
            state.avatarUrl = action.payload
        },

        setNickname(state, action: PayloadAction<string>) {
            state.nickname = action.payload
        },
    },
})

export const profileActions = profile.actions
export default profile.reducer
